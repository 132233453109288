import { ReactNode } from 'react';

import styles from './LogoContainer.module.css';

type Props = {
    url?: string;
    children?: ReactNode;
};

export const LogoContainer: React.FC<Props> = ({ url, children }) => {
    return (
        <div className={styles.container}>
            <a href={url} target="_blank">
                {children}
            </a>
        </div>
    );
};
