import { ReactNode } from 'react';
import styles from './Page.module.css';

type Props = {
    children: ReactNode;
};

export const Page: React.FC<Props> = ({ children }) => {
    return <div className={styles.page}>{children}</div>;
};
