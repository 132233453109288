import { Fragment } from 'react/jsx-runtime';
import styles from './PlaylistModal.module.css';
import { Video } from '../../../../api';
import { Modal } from 'react-overlays';
import ReactPlayer from 'react-player';
import { useEffect, useState } from 'react';

type PlaylistModalProps = {
    playlist?: any;
    show: boolean;
    onClose: () => void;
};

export const PlaylistModal: React.FC<PlaylistModalProps> = ({
    playlist,
    show,
    onClose,
}) => {
    const [playingVideoIndex, setPlayingVideoIndex] = useState<number>(0);

    useEffect(() => {
        setPlayingVideoIndex(0);
    }, [show]);

    const renderBackdrop = () => {
        return <div className={styles.backdrop} />;
    };

    const onClick = (index: number) => {
        setPlayingVideoIndex(index);
    };

    return (
        <Modal
            show={show}
            className={styles.player_modal}
            onEscapeKeyDown={onClose}
            renderBackdrop={renderBackdrop}
            backdrop={true}
            onBackdropClick={onClose}
        >
            <div className={styles.content_container}>
                <div className={styles.left_container}>
                    <h2 className={styles.player_title}>
                        {playlist?.title ?? ''}
                    </h2>
                    <div className={styles.scroll_container}>
                        {playlist?.videos.map((video: any, index: number) => {
                            return (
                                <button
                                    key={video.title}
                                    className={`${styles.video_button} ${index === playingVideoIndex ? styles.video_button_active : ''}`}
                                    onClick={() => onClick(index)}
                                >
                                    {video.title}
                                </button>
                            );
                        })}
                    </div>
                </div>
                <ReactPlayer
                    url={playlist?.videos[playingVideoIndex].src ?? ''}
                    width="100%"
                    height="100%"
                    controls={true}
                    playing={false}
                />
                <button
                    className={styles.close_player_button}
                    onClick={onClose}
                >
                    X
                </button>
            </div>
        </Modal>
    );
};
