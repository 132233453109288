import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { NavigationButton } from './components/NavigationButton/NavigationButton';
import { Videos } from './components/pages/videos/Videos';
import { Software } from './components/pages/software/Software';
import { Community } from './components/pages/community/Community';
import { Impressum } from './components/pages/impressum/Impressum';
import { CookieModal } from './components/CookieModal/CookieModal';
import { Home } from './components/pages/home/Home';

function App() {
    const [activePageIndex, setActivePageIndex] = useState<number>(0);

    return (
        <div className="App">
            <BrowserRouter>
                <NavigationBar id="nav">
                    <NavigationButton
                        id="navbutton_home"
                        label="home"
                        path="/"
                        active={activePageIndex === 0}
                        onClick={() => setActivePageIndex(0)}
                    />
                    <NavigationButton
                        id="navbutton_games"
                        label="software"
                        path="/software"
                        active={activePageIndex === 2}
                        onClick={() => setActivePageIndex(2)}
                    />
                    <NavigationButton
                        id="navbutton_videos"
                        label="videos"
                        path="/videos"
                        active={activePageIndex === 1}
                        onClick={() => setActivePageIndex(1)}
                    />
                </NavigationBar>
                <main>
                    <Routes>
                        <Route path="/" Component={Home} />
                        <Route path="/videos" Component={Videos} />
                        <Route path="/software" Component={Software} />
                        <Route path="/community" Component={Community} />
                    </Routes>
                </main>
                <footer>
                    <div className="footer-grid">
                        <div className="footer-half">
                            <h2>Impressum</h2>
                            <p>
                                David Brune
                                <br />
                                20259 Hamburg
                                <br />
                                <a href="mailto:Tarrokk@gmail.com">
                                    Tarrokk@gmail.com
                                </a>
                            </p>
                        </div>
                        <div className="footer-half">
                            <h2>privacy & cookie policy</h2>
                            <span>
                                Cookies are small text files that are stored on
                                your computer to collect data that allow for
                                certain functionality. We use cookies for Google
                                Analytics to track user interaction. By
                                accessing this website you consent to this use
                                of cookies.
                            </span>
                        </div>
                    </div>
                </footer>
            </BrowserRouter>
            <CookieModal show={true} />
        </div>
    );
}

export default App;
